<template>
	<div class="hero-image" :class="{ type, 'active-top-bar': activeTopBar }">
		<slot>
			<picture>
				<source
					v-if="item.imagePortraitWebp"
					:srcset="item.imagePortraitWebp"
					type="image/webp"
					media="(max-width: 500px)"
				/>
				<source v-if="item.imagePortrait" :srcset="item.imagePortrait" media="(max-width: 500px)" />
				<source :srcset="item.imageWebp" type="image/webp" />
				<source :srcset="item.image" />
				<img class="object-fit" :src="item.image" :alt="item.imageAlt" />
			</picture>
		</slot>
	</div>
</template>

<script setup>
defineProps({
	item: {
		type: Object,
		default: () => {},
	},
	type: {
		type: String,
		default: '',
	},
});

const activeTopBar = useState('activeTopBar', () => true);
</script>

<style lang="scss" scoped>
.hero-image {
	overflow: hidden;

	&.fullscreen {
		height: 100vh;
	}

	img {
		animation: zoom-in 11000ms ease-in-out forwards;
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.06);
	}
}

@keyframes zoom-in {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.06);
	}
}
</style>
